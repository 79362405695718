import { FC, memo } from 'react'
import classNames from 'classnames'

import BaseButton from '~/components/BaseButton/BaseButton'

import css from '#/css/components/Footer/Footer.module.css'

export interface FooterProps {
  className?: string
  mainLink: {
    label: string
    link: string
  }
  aditionalLinks: {
    label: string
    link: string
  }[]
}

export interface ViewProps extends FooterProps {}

// View (pure and testable the controller)
export const View: FC<ViewProps> = ({ className, mainLink, aditionalLinks }) => {
  return (
    <footer className={classNames('Footer', css.root, className)}>
      <div className={css.links}>
        <BaseButton target="_blank" to={mainLink.link} aria-label={mainLink.label}>
          {mainLink.label}
        </BaseButton>

        <ul className={css.aditional}>
          {aditionalLinks.map((link, index) => {
            return (
              <li key={index}>
                <BaseButton target="_blank" to={link.link} aria-label={mainLink.label}>
                  {link.label}
                </BaseButton>
              </li>
            )
          })}
        </ul>
      </div>
    </footer>
  )
}

View.displayName = 'Footer-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const Footer: FC<FooterProps> = (props) => {
  return <View {...props} />
}

export default memo(Footer)
