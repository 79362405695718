import { cssBundleHref } from '@remix-run/css-bundle'
import { Links, Meta, Scripts, ScrollRestoration } from '@remix-run/react'
import { type LinksFunction, type MetaFunction } from '@shopify/remix-oxygen'

import strings from '~/data/strings'

import generateHeadProps from '~/utils/generate-head-props'
import initGsap from '~/utils/init-gsap'

import Layout from '~/components/Layout/Layout'

import globalCssHref from '#/css/styles/global.css'

export const loader = async () => {
  // Switch to CMS data here if needed
  return { common: strings.common }
}

export const links: LinksFunction = () => {
  return [
    { rel: 'preconnect', href: 'https://cdn.shopify.com' },
    { rel: 'preconnect', href: 'https://shop.app' },
    { rel: 'icon', type: 'image/svg+xml', href: require('../public/favicons/safari-pinned-tab.svg') },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: require('../public/favicons/favicon-16x16.png') },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: require('../public/favicons/favicon-32x32.png') },
    { rel: 'icon', type: 'image/png', sizes: '72x72', href: require('../public/favicons/favicon-72x72.png') },
    { rel: 'icon', type: 'image/png', sizes: '144x144', href: require('../public/favicons/favicon-144x144.png') },
    { rel: 'icon', type: 'image/png', sizes: '150x150', href: require('../public/favicons/favicon-150x150.png') },
    { rel: 'icon', type: 'image/png', sizes: '192x192', href: require('../public/favicons/favicon-192x192.png') },
    { rel: 'icon', type: 'image/png', sizes: '384x384', href: require('../public/favicons/favicon-384x384.png') },
    { rel: 'icon', type: 'image/png', sizes: '512x512', href: require('../public/favicons/favicon-512x512.png') },
    { rel: 'apple-touch-icon', sizes: '180x180', href: require('../public/favicons/apple-touch-icon.png') },
    { rel: 'shortcut icon', href: require('../public/favicons/favicon.ico') },
    { rel: 'mask-icon', href: require('../public/favicons/safari-pinned-tab.svg'), color: '#000' },
    { rel: 'manifest', href: require('../public/favicons/site.webmanifest'), crossOrigin: 'anonymous' },
    { rel: 'stylesheet', href: globalCssHref },
    ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])
  ]
}

export const meta: MetaFunction = ({ location }) => {
  return generateHeadProps(location.pathname)
}

initGsap()

export default function App() {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <Layout />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}
