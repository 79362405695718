import { gsap } from '~/imports/gsap.client'

/**
 * Lock and unlock body scroll with page position restoration
 */
class Service {
  scrollPosY = 0
  isLocked = false

  lock = () => {
    if (typeof window === 'undefined') return
    this.scrollPosY = document.documentElement.scrollTop || document.body.scrollTop
    document.body.style.position = 'fixed'
    document.body.style.overflowY = 'scroll'
    document.body.style.marginTop = `-${this.scrollPosY}px`
    this.isLocked = true
  }

  unlock = (skipPositionRestore = false) => {
    if (typeof window === 'undefined' || !this.isLocked) return
    document.body.style.position = ''
    document.body.style.overflowY = ''
    document.body.style.marginTop = ''
    !skipPositionRestore && gsap.set(window, { scrollTo: { x: 0, y: this.scrollPosY } })
    this.isLocked = false
  }
}

const LockBodyScrollService = new Service()

export default LockBodyScrollService
