import { CustomEase, gsap, ScrollToPlugin } from '~/imports/gsap.client'

function initGsap() {
  if (typeof document !== 'undefined') {
    gsap.defaults({ ease: 'power2.out', duration: 1 })
    gsap.registerPlugin(CustomEase, ScrollToPlugin)
    // CustomEase.create('AnimIn1', '0.57, 0, 0, 1')

    // Register only the effects you need.
  }
}

export default initGsap
