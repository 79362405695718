import sass from '~/utils/sass'

export const ssrLayout = {
  mobile: false,
  tabletPortrait: false,
  tabletLandscape: false,
  desktop: true
  // large: false
}

function getLayout() {
  if (typeof window !== 'undefined') {
    const matchTabletPortrait = window.matchMedia(`(min-width: ${sass['breakpoint-tablet-portrait']}px)`)
    const matchTabletLandscape = window.matchMedia(`(min-width: ${sass['breakpoint-tablet-landscape']}px)`)
    const matchDesktop = window.matchMedia(`(min-width: ${sass['breakpoint-desktop']}px)`)
    // const matchLarge = window.matchMedia(`(min-width: ${sass['breakpoint-large']}px)`);

    // const large = matchLarge.matches;
    const desktop = matchDesktop.matches //&& !large;
    const tabletLandscape = matchTabletLandscape.matches && !desktop //&& !large;
    const tabletPortrait = matchTabletPortrait.matches && !tabletLandscape && !desktop //&& !large;
    const mobile = !tabletPortrait && !tabletLandscape && !desktop //&& !large;

    return {
      mobile,
      tabletPortrait,
      tabletLandscape,
      desktop
      // large
    }
  } else {
    return ssrLayout
  }
}

export default getLayout
