import { FC, memo, useEffect, useState } from 'react'
import classNames from 'classnames'

import { Strings } from '~/data/types'

import LockBodyScrollService from '~/services/lock-body-scroll'

import copy from '~/utils/copy'

import useDetect from '~/hooks/use-detect'
import useLayout from '~/hooks/use-layout'

import Footer from '~/components/Footer/Footer'

import css from '#/css/components/ScreenMobile/ScreenMobile.module.css'

import ShopifyPartnersLogo from '#/svgs/ShopifyPartnersLogo'

export interface ScreenMobileProps {
  className?: string
  common: Strings['common']
}

export interface ViewProps extends ScreenMobileProps {}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ className, common }) => {
  const [isEnabled, setEnabled] = useState(false)
  const layout = useLayout()
  const detect = useDetect()

  useEffect(() => {
    if (layout.desktop || layout.tabletLandscape || layout.tabletPortrait) {
      setEnabled(false)
      LockBodyScrollService.unlock(true)
    } else {
      setEnabled(true)
      LockBodyScrollService.lock()
    }

    return () => {
      setEnabled(false)
    }
  }, [detect.device?.desktop, detect.device?.tablet, layout.desktop, layout.tabletLandscape, layout.tabletPortrait])

  return (
    <div className={classNames('ScreenMobile', className, css.root, { [css.enabled]: isEnabled })}>
      <ShopifyPartnersLogo className={css.logo} />

      <div className={css.content}>
        <h1 className={css.title} {...copy.html(common.screenMobile.title)} />
        <p className={css.description} {...copy.html(common.screenMobile.description)} />
      </div>

      <Footer {...common.footer} />
    </div>
  )
}

View.displayName = 'ScreenMobile-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const ScreenMobile: FC<ScreenMobileProps> = (props) => {
  return <View {...props} />
}

export default memo(ScreenMobile)
