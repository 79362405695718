import vars from '#/css/styles/export-vars.module.css'

export const colors = Object.entries(vars)
  .filter(([_, value]) => value.startsWith('#'))
  .reduce((acc, entry) => {
    acc[entry[1]] = entry[0]
    return acc
  }, {} as { [key: string]: string })

export default vars

// Usage:
// sass['white']
// sass['black']
// etc...
