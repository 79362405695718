import { useEffect, useState } from 'react'
import detect from '@jam3/detect'

function useDetect() {
  const [os, setOs] = useState<typeof detect.os | null>(null) // ssr
  const [device, setDevice] = useState<typeof detect.device | null>(null) // ssr
  const [browser, setBrowser] = useState<typeof detect.browser | null>(null) // ssr

  useEffect(() => {
    setOs(detect.os)
    setDevice(detect.device)
    setBrowser(detect.browser)
  }, [])

  return { os, device, browser }
}

export default useDetect
