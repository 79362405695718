import { FC, memo, RefObject, useCallback } from 'react'
import { Outlet, useLoaderData } from '@remix-run/react'
import classNames from 'classnames'

import strings from '~/data/strings'
import { PageHandle } from '~/data/types'

import ScreenMobile from '~/components/ScreenMobile/ScreenMobile'

import Footer from '~/components/Footer/Footer'

import css from '#/css/components/Layout/Layout.module.css'

export const loader = async () => {
  return {
    common: strings.common
  }
}

export interface LayoutProps {}

// View (pure and testable component, receives props from the controller)
export const Layout: FC<LayoutProps> = () => {
  const data = useLoaderData<typeof loader>()

  // Sometimes pages are not ready to be animated in immediatelly, so we wait for them to be ready
  const handlePageReady = useCallback((pageHandle?: RefObject<PageHandle>) => {
    pageHandle?.current?.animateIn?.()
  }, [])

  return (
    <div className={classNames('Layout', css.root)}>
      <Outlet context={{ onReady: handlePageReady }} />
      <ScreenMobile common={data.common} />
      <Footer {...data.common.footer} />
    </div>
  )
}

Layout.displayName = 'Layout'

export default memo(Layout)
